import React from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  Icon,
  Grid,
  GridItem,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FaCamera,
  FaBookOpen,
  FaLaptopCode,
  FaBullhorn,
  FaPaintBrush,
  FaHandshake,
} from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";

const JoinUs = () => {
  const cardBg = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.600", "gray.300");

  const teams = [
    {
      icon: FaCamera,
      value: "photography",
      title: "فريق التصوير",
      description:
        "فريق التصوير هو احد اهم الفرق في لجنة الكتاب السنوي حيث تتمحور مهام فريق التصوير حول تنسيق و تنظيم و تصوير الخريجين وهو عنصر رئيس في نجاح الكتاب و تحقيق اهدافه .",
    },
    {
      icon: FaBookOpen,
      value: "editorial",
      title: "فريق التحرير",
      description:
        "يهتم فريق التحرير بشكل مباشر بمحتوى الكتاب، وكذلك توفير نسخ باللغتين العربية و الانجليزية بالاضافة إلى جمع كلمات العملاء و رئيس الجامعة و الشخصيات المعنية",
    },
    {
      icon: FaLaptopCode,
      title: "فريق البرمجيات",
      value: "tech",
      description:
        "يهتم فريق البيانات و IT بتنسيق و ترتيب البيانات الخاصة بالخريجين و اللجنة وتسهيل الوصول إليها بالاضافة الى ادارة موقع اللجنة الرسمي و تحسينه",
    },
    {
      icon: FaBullhorn,
      title: "فريق الاعلام و التسويق",
      value: "media",
      description:
        "يهتم فريق الاعلام والتسويق بنشر ثقافة الكتاب السنوي بالاضافة إلى إنتاج محتوى تسويقي وتعزيز التفاعل مع الجمهور",
    },
    {
      icon: FaPaintBrush,
      title: "فريق التصميم",
      value: "design",
      description:
        "يعمل فريق التصميم على إبراز الهوية البصرية للكتاب السنوي من خلال تصميم الصفحات، وإخراجها بأسلوب جذاب يعكس روح وموضوع الكتاب.",
    },
    {
      icon: FaHandshake,
      title: "فريق الشراكات",
      value: "partnerships",
      description:
        "يقوم فريق الشراكات بمد جسور التعاون مع الشركات والجهات الأخرى لدعم الكتاب السنوي وزيادة فاعليته وانتشاره.",
    },
  ];

  return (
    <Box dir="rtl" p={10}>
      <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6}>
        {teams.map((team, index) => (
          <GridItem
            key={index}
            bg={cardBg}
            borderRadius="lg"
            boxShadow="md"
            p={6}
            display="flex"
            flexDirection="column"
          >
            <VStack spacing={4} w="full">
              <Icon as={team.icon} boxSize="50px" color="green.600" />
              <Text fontSize="xl" fontWeight="bold" color="green.600">
                {team.title}
              </Text>
              <Text fontSize="md" color={textColor} textAlign="center">
                {team.description}
              </Text>
              <Button
                as={RouterLink}
                to={`/join/${team.value}`}
                colorScheme="green"
              >
                الانضمام
              </Button>
            </VStack>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default JoinUs;
