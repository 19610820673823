import React from "react";
import { SimpleGrid, Box, Text, Icon, Heading } from "@chakra-ui/react";
import { FaGraduationCap, FaUsers, FaBuilding } from "react-icons/fa";

const Achievements = () => {
  const achievements = [
    {
      icon: FaGraduationCap,
      label: "الخريجون",
      value: "+4000",
      description: "خريج تم تصويره",
    },
    {
      icon: FaBuilding,
      label: "كلمات الخريجين",
      value: "+10",
      description: "كلمة خريجين",
    },
    {
      icon: FaUsers,
      label: "المتطوعين",
      value: "92",
      description: "طالب متطوع",
    },
    {
      icon: FaUsers,
      label: "المتطوعين",
      value: "+20",
      description: "تخصص مختلف",
    },
    {
      icon: FaGraduationCap,
      label: "الخريجون",
      value: "+4000",
      description: "خريج تم تصويره",
    },
    {
      icon: FaBuilding,
      label: "الشراكات",
      value: "6",
      description: "شركاء استراتيجيون",
    },
  ];

  return (
    <Box py={10} px={5}>
      <Text as="h2" size="2xl" textAlign="center" mb={10}>
        إنجازات اللجنة
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
        {achievements.map((item, index) => (
          <Box
            key={index}
            textAlign="center"
            p={5}
            borderRadius="md"
            boxShadow="md"
          >
            <Icon as={item.icon} boxSize={12} color="green.500" />
            <Heading as="h3" size="lg" mt={4} color="gray.500">
              {item.value}
            </Heading>
            <Text color="gray">{item.description}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Achievements;
