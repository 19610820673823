// deno-lint-ignore-file no-dupe-keys
import { colors } from "../theme/theme.js";


const CollegeColors = {
  ES: colors.kfupm.engineering,
  IM: colors.kfupm.businessSchool,
  ED: colors.kfupm.design,
  ARC: colors.kfupm.design,
  CS: colors.kfupm.computing,
  CM: colors.kfupm.chemicals,
  PG: colors.kfupm.petroleumEngineering,
  Master: colors.kfupm.graduate,
};

const MajorSlogans = {
  MKT: "الماركتير الحلو، اللي يبيع الرمل في الصحراء 🛍️",
  MGT: "مديرنا الفذ، القائد اللي ما ينام إلا والكل مرتاح 🎩",
  MIS: "معلوماتي الذكي، يحول الداتا لذهب ولا يغلط أبداً 💻",
  FIN: "المالي الفلتة، يعد الفلوس بعيون مغمضة وقلب مفتوح 💸",
  ACCT: "المحاسب الدقيق، حتى الفلس ما يفوته 📊",
  MBA: "ماجستير إدارة الأعمال، العقل المدبر وراء كل نجاح 🏆",
  EMBA: "التنفيذيون الكبار، يصنعون المستقبل بقراراتهم الحكيمة 🌐",
  BA: "إداريو الأعمال، يبنون الإمبراطوريات من الفكرة إلى الواقع 🌟",
  HRM: "أساتذة الموارد البشرية، يجدون الألماس بين الناس 💎",
  SCM: "أبطال سلسلة التوريد، يحافظون على تدفق كل شيء بسلاسة 🔄",
  SCE: "مهندسو البرمجيات، يصنعون العوالم من الأكواد 🌍",
  ME: "المهندسون الميكانيكيون، قلب الصناعة النابض 🛠️",
  EE: "مهندسو الكهرباء، يضيئون العالم بأفكارهم 💡",
  AEE: "المهندسون الكهربائيون التطبيقيون، العباقرة وراء الابتكارات ⚡",
  TELE: "خبراء الاتصالات، يوصلون الأصوات والأحلام للعالم 📡",
  PHYS: "علماء الفيزياء، يكتشفون أسرار الكون 🌌",
  AE: "مهندسو الطيران، يحلقون بأحلامهم عالياً في السماء ✈️",
  QC: "خبراء الجودة، لا شيء يفوت عيونهم الثاقبة 🔍",
  SRE: "مهندسو البرمجيات الموثوقة، يبنون الأنظمة الصامدة 💻",
  MSE: "مهندسو المواد، يكتشفون ويصنعون المستقبل من الذرات 🧬",
  WTD: "خبراء تقنية المياه، يحافظون على تدفق الحياة 💧",
  IPC: "مهندسو الصناعة والنظم، يحسنون العالم خطوة بخطوة 📈",
  WCN: "متخصصون في شبكات الاتصالات اللاسلكية، يربطون العالم بلا حدود 🌐",
  PDI: "مبدعو تصميم المنتجات، يحولون الأفكار إلى واقع ملموس🎨",
  RAIS: "رواد الروبوتات والأنظمة الذكية، يبنون المستقبل بين أيديهم 🤖",
  CHE: "المهندسون الكيميائيون، يحولون الجزيئات إلى معجزات 🔬",
  CIE: "مهندسو الإنشاءات، يشكلون عالمنا الفيزيائي بكل دقة 🏗️",
  AAE: "مهندسو الطيران المتقدمون، يتخطون حدود السماء 🚀",
  AME: "مهندسو الميكانيكا التطبيقيين، يطبقون النظريات لحلول واقعية 🛠️",
  EM: "أساتذة إدارة الهندسة، يرسمون خرائط النجاح في المشاريع 📊",
  CEM: "خبراء الإنشاء والإدارة، يبنون المستقبل بكفاءة وفعالية 🏗️",
  CE: "خبراء الإنشاء والإدارة، يبنون المستقبل بكفاءة وفعالية 🏗️",
  FM: "أبطال الإدارة المالية، ينسجون نجاحات الأعمال من الأرقام 💰",
  ARE: "المعماريون الرواد، يصممون فضاءات تلهم الأجيال 🖼️",
  ARC: "المعماريون، يرسمون أحلام الفضاءات على أرض الواقع 🏛️",
  PM: "أسياد إدارة المشاريع، يحولون الفكرة إلى واقع ناجح 🚀",
  ESE: "علماء البيئة والهندسة، يحمون كوكبنا لأجيال المستقبل 🌍",
  SEM: "مدراء هندسة الأنظمة، ينظمون الفوضى إلى حلول متكاملة 🔧",
  SSC: "علماء العلوم الاجتماعية، يفهمون ما يجعل المجتمعات تدور 🔄",
  ACE: "خبراء الإنشاء والإدارة، يبنون المستقبل بكفاءة وفعالية 🏗️",
  CHEM: "الكيميائيون، سحرة المعمل الذين يغيرون العالم بتجاربهم 🧪",
  LS: "علماء الحياة، يكشفون أسرار الوجود ويحافظون عليه 🌱",
  PCE: "مهندسو البترول الكيميائي، يستخرجون الطاقة من أعماق الأرض ⛏️",
  PSE: "مهندسو نظم البترول، يحافظون على تدفق الذهب الأسود 🛢️",
  FA: "فنانو الجمال، يرسمون العالم بألوانهم الزاهية 🎨",
  BIOE: "مهندسو الطب الحيوي، يصنعون المستقبل بين التكنولوجيا والطب 💊",
  ACH: "المهندسون الكيميائيون المتقدمون، يحولون التحديات إلى حلول مبتكرة لعالم أفضل 🌐",
  PETE: "مهندسو البترول، رواد استكشاف الأعماق لتأمين مستقبل الطاقة ⚒️",
  GEOP: "الجيوفيزيائيون، يكتشفون أسرار الأرض لفهم الماضي وتوقع المستقبل 🌎",
  GEOL: "الجيولوجيون، حراس التاريخ الأرضي، يروون قصص الصخور عبر العصور 🏔️",
  ENVS: "علماء البيئة، حماة الطبيعة ومبتكرو الحلول المستدامة 🍃",
  ASTA: "الإحصائيون التطبيقيون، يحللون البيانات لرسم خرائط النجاح 📈",
  COE: "مهندسو الحاسب، يبنون أساس العالم الرقمي ويشكلون مستقبل التكنولوجيا 💻",
  ISE: "مهندسو الأنظمة الصناعية، يبتكرون أنظمة لعالم أكثر فعالية وكفاءة 🏭",
  CS: "علماء الحاسوب، مهندسو المستقبل الرقمي، يخلقون عوالم جديدة في كل سطر كود 🌐",
  CNW: "خبراء شبكات الحاسوب، يربطون العالم بخيوط من البيانات 🌍",
  CA: "متخصصو تطبيقات الحاسب، يحولون الأفكار إلى حلول تكنولوجية تلامس حياتنا اليومية 📲",
  AI: "علماء الذكاء الاصطناعي، يزرعون الذكاء في آلات لخلق مستقبل أذكى 🤖",
  VC: "متخصصو الحوسبة المرئية، يجعلون البيانات تتحدث بصريًا 📊",
  DSA: "خبراء علم البيانات والتحليلات، يستخرجون الحكمة من البيانات الضخمة 🔍",
  ITES: "محترفو تكنولوجيا المعلومات والأنظمة، يديرون شرايين العصر الرقمي 💾",
  HPCC: "متخصصو الحوسبة السحابية عالية الأداء، يدفعون حدود الحساب إلى الأفق 🌩️",
  MATH: "الرياضياتيون، لغة الكون بأرقام ومعادلات، مفتاح فهم الوجود 🔑",
  CYBS: "خبراء الأمن السيبراني، درع الحماية في عالم تهدده الهجمات الرقمية 🛡️",
  MR: "الباحثون الطبيون، يمهدون الطريق نحو اكتشافات طبية ثورية 💊",
  SWE: "مبرمجنا الذهبي، يكتب الشفرات والاكواد اللي تسهل حياتنا 👨‍💻",
  AS: "عالمنا الكبير، الفهم عنده بحر والعقل ينير 🧠",
};

export default MajorSlogans;

export { colors, CollegeColors, MajorSlogans };
