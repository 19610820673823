import React from "react";
import { Box, Text, Icon, useColorMode } from "@chakra-ui/react";
import { FaHandshake } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PartnershipsTeamForm = () => {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  React.useEffect(() => {
    const iframe = document.getElementById("google-form-iframe");
    if (iframe) {
      if (colorMode === "dark") {
        iframe.style.backgroundColor = "#333";
        iframe.style.color = "#fff";
      } else {
        iframe.style.backgroundColor = "#fff";
        iframe.style.color = "#000";
      }
    }
  }, [colorMode]);

  React.useEffect(() => {
    const iframe = document.getElementById("google-form-iframe");

    const handleIframeLoad = () => {
      try {
        if (iframe.contentWindow.location.href.includes("/thank-you")) {
          navigate("/thank-you");
        }
      } catch (error) {
        console.error("Cross-origin access error:", error);
      }
    };

    iframe.addEventListener("load", handleIframeLoad);

    return () => {
      iframe.removeEventListener("load", handleIframeLoad);
    };
  }, [navigate]);

  return (
    <>
      <Box
        w="100%"
        p={8}
        textAlign="center"
        bgGradient="linear(to-r, green.400, gold)"
        color="white"
      >
        <Icon as={FaHandshake} boxSize={12} mb={4} />
        <Text fontSize="3xl" fontWeight="bold" color="white">
          فريق الشراكات
        </Text>
        <Text fontSize="lg" mt={2} color="white">
          يقوم فريق الشراكات بمد جسور التعاون مع الشركات والجهات الأخرى لدعم
          الكتاب السنوي وزيادة فاعليته وانتشاره.
        </Text>
      </Box>

      <Box
        w="100%"
        maxW="800px"
        mx="auto"
        mt={8}
        boxShadow="md"
        p={4}
        borderRadius="md"
        bg="white"
      >
        <iframe
          id="google-form-iframe"
          src="https://docs.google.com/forms/d/e/1FAIpQLScVi5xN4RPpiPC8h4CkgB0H4yaeumUQ5htvhohD4Lmy6Rbobg/viewform?embedded=true"
          width="100%"
          height="800px"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          style={{ border: "none" }}
          title="Partnerships Team Form"
        >
          Loading…
        </iframe>
      </Box>
    </>
  );
};

export default PartnershipsTeamForm;
