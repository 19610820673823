import React from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import { useSpring, animated } from "@react-spring/web";
import Header from "./components/Header";
import Achievements from "./components/Achievements";
import AlertComponent from "./components/AlertComponent";

function Home() {
  const { colorMode } = useColorMode();
  const fontColor = colorMode === "light" ? "black" : "white";

  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  return (
    <Box color={fontColor}>
      <AlertComponent />
      <animated.div style={style}>
        <Header />
        <Achievements />
      </animated.div>
    </Box>
  );
}

export default Home;
