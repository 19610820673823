import React from "react";
import { Box } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import ImageGetter from "./pages/ImageGetter/ImageGetter";
import ContactUs from "./pages/contactus/ContactUs";
import JoinUs from "./pages/joinus/JoinUs";
import TechTeamForm from "./pages/joinus/pages/TechTeamForm";
import MediaTeamForm from "./pages/joinus/pages/MediaTeamForm";
import DesignTeamForm from "./pages/joinus/pages/DesignTeamForm";
import PartnershipsTeamForm from "./pages/joinus/pages/PartnershipsTeamForm";
import EditorialTeamForm from "./pages/joinus/pages/EditorialTeamForm";
import PhotographyTeamForm from "./pages/joinus/pages/PhotographyTeamForm";
import ThankYouPage from "./pages/joinus/pages/ThankYouPage";
import bayanplus from "bayanplus-js";

bayanplus.init({
  projectId: process.env.REACT_APP_BAYAN_PLUS_KEY,
  isDev: false,
});

function App() {
  return (
    <Router>
      <Box minH="100vh" color="black">
        <Header />
        <Box pt="80px" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/images" element={<ImageGetter />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/join" element={<JoinUs />} />
          <Route path="/join/tech" element={<TechTeamForm />} />
          <Route path="/join/media" element={<MediaTeamForm />} />
          <Route path="/join/design" element={<DesignTeamForm />} />
          <Route path="/join/partnerships" element={<PartnershipsTeamForm />} />
          <Route path="/join/editorial" element={<EditorialTeamForm />} />
          <Route path="/join/photography" element={<PhotographyTeamForm />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
        </Routes>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
