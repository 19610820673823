import React, { useState } from "react";
import {
  Box,
  Text,
  Input,
  Textarea,
  Button,
  VStack,
  Grid,
  GridItem,
  Icon,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const formBg = useColorModeValue("white", "gray.700");

  const submitForm = (e) => {
    e.preventDefault();
    const formElement = document.querySelector("form");
    const formData = new FormData(formElement);
    setIsLoading(true);

    fetch("https://script.google.com/macros/s/AKfycbz8UJaX_HbExovT6KGLnKhSVqRe0FO_fo0XPDls-_FYYMBk-uh14zdaEI9lrrr4_KVFDg/exec", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) throw new Error("Network response was not ok");
        return res.json();
      })
      .then((data) => {
        toast({
          title: "تم الإرسال بنجاح",
          description: "تم إرسال رسالتك بنجاح.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "خطأ في الإرسال",
          description: "حدث خطأ أثناء إرسال رسالتك. حاول مرة أخرى.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box dir="rtl">
      <Box
        as="section"
        bgImage="url('/bg.png')"
        bgSize="cover"
        bgPosition="center"
        position="relative"
        h="300px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        color="white"
        _before={{
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          bg: "rgba(0, 128, 64, 0.6)",
          zIndex: 1,
        }}
      >
        <Text fontSize="4xl" fontWeight="bold" zIndex="2" color="white">
          تواصل معنا
        </Text>
      </Box>

      <Box as="section" p={10}>
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={10}>
          <GridItem bg={formBg} p={6} borderRadius="lg" boxShadow="md">
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              وصل صوتك
            </Text>
            <VStack as="form" spacing={4} w="full" onSubmit={submitForm}>
              <Box w="full">
                <Text mb={1}>الاسم الاول:</Text>
                <Input type="text" name="firstName" required placeholder="ادخل اسمك الاول" w="full" />
              </Box>
              <Box w="full">
                <Text mb={1}>اسم العائلة:</Text>
                <Input type="text" name="lastName" required placeholder="ادخل اسم العائلة" w="full" />
              </Box>
              <Box w="full">
                <Text mb={1}>الرقم الجامعي:</Text>
                <Input type="text" name="studentId" required placeholder="ادخل الرقم الجامعي" w="full" />
              </Box>
              <Box w="full">
                <Text mb={1}>رقم الجوال:</Text>
                <Input type="tel" name="phone" required placeholder="ادخل رقم الجوال" w="full" />
              </Box>
              <Box w="full">
                <Text mb={1}>الرسالة:</Text>
                <Textarea name="message" required placeholder="اكتب رسالتك هنا" w="full" />
              </Box>
              <Button colorScheme="green" type="submit" w="full" isLoading={isLoading}>
                إرسال
              </Button>
            </VStack>
          </GridItem>

          <GridItem p={6} borderRadius="lg" boxShadow="md">
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              التواصل الاجتماعي
            </Text>
            <VStack spacing={4} alignItems="stretch" w="full">
              <Button
                as="a"
                href="https://twitter.com/KFUPMYearBook"
                target="_blank"
                leftIcon={<Icon as={FaSquareXTwitter} />}
                variant="outline"
                justifyContent="flex-start"
                w="full"
              >
                @KFUPMYearBook
              </Button>
              <Button
                as="a"
                href="https://linkedin.com/in/KFUPM-YearBook"
                target="_blank"
                leftIcon={<Icon as={FaLinkedin} />}
                variant="outline"
                justifyContent="flex-start"
                w="full"
              >
                @KFUPM-YearBook
              </Button>
            </VStack>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactUs;
