import React from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useSpring, animated } from "@react-spring/web";
import bgImage from "../assets/grad.png"; // Ensure this path is correct

const Intro = () => {
  const fadeInScale = useSpring({
    from: { opacity: 0, transform: "scale(0.9)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: { duration: 1000 },
  });

  const gradientOverlay = useColorModeValue(
    `linear(to-r, rgba(0, 133, 64, 0.85), rgba(218, 201, 97, 0.85)), url(${bgImage})`,
    `linear(to-l, rgba(55, 57, 56, 0.85), rgba(3, 51, 81, 0.85)), url(${bgImage})`
  );
  return (
    <Box
      bgImage={gradientOverlay}
      bgPos="center"
      bgSize="cover"
      color="white"
      p={8}
      textAlign="center"
      borderRadius="2xl"
      boxShadow="xl"
      position="relative"
    >
      <animated.div style={fadeInScale}>
        <Text fontSize="3xl" fontWeight="extrabold" mb={4}>
          تخرج دفعة 54✨
        </Text>
      </animated.div>
      <animated.div style={fadeInScale}>
        <Text fontSize="2xl" mb={4}>
          حي الله خريجيننا الحلويين
        </Text>
      </animated.div>
      <animated.div style={fadeInScale}>
        <Text fontSize="2xl" fontWeight="bold">
          لجنة الكتاب السنوي تبارك لكم تخرجكم🎓✨
        </Text>
      </animated.div>
    </Box>
  );
};

export default Intro;
