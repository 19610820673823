// components/AlertComponent.js
import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Box,
  useDisclosure,
} from "@chakra-ui/react";

const AlertComponent = () => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <>
      {isVisible && (
        <Box display="flex" justifyContent="center" alignItems="center" p={4}>
          <Alert
            status="success"
            borderRadius="lg"
            padding="1rem"
            fontSize="1.2rem"
          >
            <AlertIcon boxSize="2rem" mr={5} />
            <Box flex="1">
              <AlertTitle fontSize="1.5rem">رجعنا لكم! 🚀</AlertTitle>
              <AlertDescription lineHeight="1.5">
                رجعنا لكم بالجلة الجديدة، كونوا بالقرب!
              </AlertDescription>
            </Box>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={onClose}
            />
          </Alert>
        </Box>
      )}
    </>
  );
};

export default AlertComponent;
