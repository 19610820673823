import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Collapse,
  Link,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { FaSquareXTwitter } from "react-icons/fa6";

const NotFound = () => {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(!show);

  return (
    <Box textAlign="center" mt="4" w="full">
      <Button onClick={toggleShow} w="full">
        صورت ومالقيت صورتك؟
      </Button>
      <Collapse in={show} animateOpacity>
        <Box
          p="40px"
          color="white"
          mt="4"
          bg="red.500"
          rounded="md"
          shadow="md"
        >
          <Text>
            اذا صورتك فيها خطأ او صورت ومالقيت صورتك الرجاء التواصل معنا في اكس
            على{" "}
          </Text>
          <Text dir="ltr">
            <Link
              href="https://twitter.com/KFUPMYearbook"
              isExternal
              color="black"
            >
              <Icon as={FaSquareXTwitter} boxSize="4" mb={-1} mr={1} />
              @KFUPMYearbook
            </Link>
          </Text>
        </Box>
      </Collapse>
    </Box>
  );
};

export default NotFound;
