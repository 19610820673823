import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Text,
  Image,
  Input,
  VStack,
  Button,
  useToast,
  Flex,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { CollegeColors } from "../constants/enums";
import { MajorSlogans } from "../constants/enums";
import Loading from "../constants/Loading";
import NotFound from "./NotFound.js";

import bayanplus from "bayanplus-js";

function MainSection({ data }) {
  const [searchId, setSearchId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const resultsRef = useRef(null);
  const [readyToScroll, setReadyToScroll] = useState(false);

  const bgColor = useColorModeValue("kfupm.lightGray", "kfupm.darkGray");
  const inputBgColor = useColorModeValue("white", "gray.700");
  const buttonColor = useColorModeValue("kfupm.green", "kfupm.lightGray");
  const textColor = useColorModeValue("black", "white");
  useEffect(() => {
    setButtonDisabled(false);
  }, [searchId]);

  useEffect(() => {
    if (readyToScroll && filteredData.length > 0) {
      resultsRef.current?.scrollIntoView({ behavior: "smooth" });
      setReadyToScroll(false);
    }
  }, [readyToScroll, filteredData]);

  const handleFindPicture = () => {
    setIsLoading(true);

    if (!searchId) {
      toast({
        title: "اكتب ايدي ياحلو 🤦‍♂️",
        description: "مافيه ايدي",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    } else if (searchId.length !== 9) {
      toast({
        title: "ايدي غلط 🤦‍♂️",
        description: "ايدي الطالب يكون 9 ارقام",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);

      return;
    } else if (isNaN(searchId)) {
      toast({
        title: "ايدي غلط 🤦‍♂️",
        description: "ايدي الطالب يكون ارقام فقط",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false); // End loading after operations are done

      return;
    }

    const studentFound = data.find(
      (item) => item["STU ID"].toString() === searchId
    );

    if (studentFound?.fileId === null) {
      toast({
        title: "مالقيناك 🫣",
        description: "متاكد صورت ياحلو؟",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    if (studentFound) {
      setFilteredData([studentFound]);
      toast({
        title: "لقينا الحلو 😍",
        description: "شف شحلاتك بالبشت",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setButtonDisabled(true); // Disable the button
      setReadyToScroll(true); // Indicate ready to scroll
      bayanplus.event(`Student searched ${searchId}`); // Track the event using Bayan Plus
      setIsLoading(false);
    } else {
      setFilteredData([]);
      toast({
        title: "مالقيناك 🫣",
        description: `مافيه عندنا طالب بهذا الايدي ${searchId}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false); // End loading after operations are done
    }
  };
  return (
    <Box as="main" padding="4" bg={bgColor} color="black">
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="4"
      >
        <Box
          width="100%"
          maxWidth="480px"
          bg={inputBgColor}
          p="4"
          borderRadius="lg"
          boxShadow="base"
        >
          <VStack spacing={4}>
            <Text color={textColor} textAlign="right">
              عطنا ايديك طال عمرك
            </Text>{" "}
            <Input
              placeholder="201942950"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
              borderColor="kfupm.green"
              focusBorderColor="kfupm.gold"
              color={textColor}
            />
            <Button
              colorScheme="teal"
              variant="solid"
              onClick={handleFindPicture}
              onChange={(e) => {
                setButtonDisabled(false); // Re-enable the button on change
              }}
              bg={buttonColor}
              _hover={{ bg: "kfupm.gold" }}
              isDisabled={buttonDisabled}
            >
              بشوف صورتي
            </Button>
            <NotFound />
          </VStack>
        </Box>
        {/* Display results */}
        {filteredData.map((item, index) => (
          <Box
            key={index}
            marginBottom="4"
            borderRadius="lg"
            overflow="hidden"
            p="4"
            bg={CollegeColors[item.COLLEGE] || "gray.200"}
            width="85%"
            boxShadow="lg"
            ref={resultsRef}
          >
            <VStack color="white">
              <Text fontSize="xl" fontWeight="light">
                الله حيه <strong>{item["ARABIC NAME"].split(" ")[0]}</strong>!
              </Text>
              <Text fontSize="md">{MajorSlogans[item.MAJOR]}</Text>
              {isLoading && <Loading />}

              {item.fileId && item.fileId !== "0" && (
                <Image
                  src={`https://lh3.googleusercontent.com/d/${item.fileId}`}
                  alt={`Image of ${item["ARABIC NAME"]}`}
                  width="80%"
                  height="80%"
                  boxShadow="lg"
                  transition="transform 0.4s"
                  _hover={{
                    transform: "scale(1.02)",
                    boxShadow: "xl",
                  }}
                />
              )}
              <Link
                href={`https://drive.google.com/uc?export=view&id=${item.fileId}`}
                isExternal
                download
              >
                <Button bg={buttonColor} bgColor="kfupm.darkGray">
                  <Text color="white">حمل صورتك 📸</Text>
                </Button>
              </Link>
              <Text fontSize="sm" fontWeight="light">
                  انشر الموقع مع اهلك واصحابك واعطيهم ايديك وشوف ردة فعلهم 😍
              </Text>
            </VStack>
          </Box>
        ))}
      </Flex>
    </Box>
  );
}

export default MainSection;
