import { extendTheme } from "@chakra-ui/react";

export const colors = {
  kfupm: {
    green: "#008540",
    gold: "#DAC961",
    forest: "#00573F",
    stone: "#AA8A00",
    petrol: "#003E51",
    lightGray: "#f0f0f0",
    darkGray: "#373938",
    generalStudies: "#6957B8",
    petroleumEngineering: "#981B32",
    businessSchool: "#00497A",
    computing: "#97A5B9",
    chemicals: "#FFB92A",
    engineering: "#FC4D0F",
    design: "#674838",
    graduate: "#008540",
  },
};

const fonts = {
  heading: "Poppins,'Hacen Tunisia', sans-serif",
  body: "'Poppins', 'Hacen Tunisia', sans-serif",
  arabic: "'Hacen Tunisia', sans-serif",
};

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  direction: "rtl",
  colors,
  fonts,
  config,
  styles: {
    global: (props) => ({
      body: {
        bg:
          props.colorMode === "light"
            ? colors.kfupm.lightGray
            : colors.kfupm.darkGray,
        color: props.colorMode === "light" ? "black" : "white",
        transition: "background-color 0.2s ease, color 0.2s ease",
      },
      h1: {
        color:
          props.colorMode === "light" ? colors.kfupm.green : colors.kfupm.gold,
      },
      h2: {
        color:
          props.colorMode === "light" ? colors.kfupm.petrol : colors.kfupm.gold,
      },
      p: {
        color: props.colorMode === "light" ? "black" : "white",
      },
      a: {
        color:
          props.colorMode === "light" ? colors.kfupm.green : colors.kfupm.gold,
        _hover: {
          textDecoration: "underline",
        },
      },
    }),
  },
});

export default theme;
