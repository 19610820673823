import React from 'react';
import { Box, Heading, Text, Image, useToast } from "@chakra-ui/react";

const ThankYouPage = () => {
  const toast = useToast();

  React.useEffect(() => {
    toast({
      title: "تم تقديم النموذج بنجاح",
      description: "شكراً لك على تقديم النموذج!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  }, [toast]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minH="100vh"
      bgGradient="linear(to-br, green, gold)"
      p={8}
      textAlign="center"
    >
      <Image
        src={`./books.png`} // Reference to public folder
        alt="Books"
        mb={6}
      />
      <Heading
        fontSize={{ base: "3xl", md: "4xl" }}
        color={"gold"}
        fontWeight="bold"
        mb={4}
      >
        شكراً لتقديم النموذج!
      </Heading>
      <Text fontSize={{ base: "md", md: "lg" }} color="gray.700" maxW="600px">
        سيتم مراجعة طلبك في أقرب وقت ممكن. نشكرك على اهتمامك وانضمامك معنا.
      </Text>
    </Box>
  );
};

export default ThankYouPage;
