import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  IconButton,
  useColorMode,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Link,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaMoon, FaSun, FaBars } from "react-icons/fa";
import Logo from "../assets/YB_LogoText_Green.png";

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [hasScrolled, setHasScrolled] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const textColor = useColorModeValue("black", "white");
  const hoverColor = useColorModeValue("gray.600", "gray.300");

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setHasScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      as="header"
      w="100%"
      p={4}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bg={
        hasScrolled
          ? colorMode === "light"
            ? "whiteAlpha.800"
            : "blackAlpha.800"
          : "transparent"
      }
      backdropFilter={hasScrolled ? "blur(10px)" : "none"}
      position="fixed"
      top="0"
      zIndex="banner"
      boxShadow={hasScrolled ? "sm" : "none"}
      transition="all 0.3s ease-in-out"
    >
      <Link href="/">
        <Image src={Logo} alt="YB Logo" htmlWidth="120px" />
      </Link>
      {/* Mobile Menu Icon */}
      <Box display={{ base: "block", md: "none" }}>
        <IconButton
          aria-label="Open Menu"
          icon={<FaBars />}
          onClick={onOpen}
          size="md"
          isRound={true}
          _hover={{ bg: "gray.200" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        />
      </Box>

      {/* Desktop Links */}
      <Box display={{ base: "none", md: "flex" }} alignItems="center" gap={6}>
        <Link
          href="/"
          fontSize="lg"
          color={textColor}
          _hover={{ color: hoverColor }}
        >
          الصفحة الرئيسية
        </Link>
        <Link
          href="/join"
          fontSize="lg"
          color={textColor}
          _hover={{ color: hoverColor }}
        >
          انضم الينا
        </Link>
        <Link
          href="/contact"
          fontSize="lg"
          color={textColor}
          _hover={{ color: hoverColor }}
        >
          تواصل معنا
        </Link>
        <Link
          href="/images"
          fontSize="lg"
          color={textColor}
          _hover={{ color: hoverColor }}
        >
          صور الكتاب
        </Link>
        <IconButton
          aria-label="Toggle color mode"
          icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
          onClick={toggleColorMode}
          size="md"
          isRound={true}
        />
      </Box>

      {/* Drawer for Mobile Menu */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>القائمة</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="start">
              <Link
                href="/"
                onClick={onClose}
                fontSize="lg"
                color={textColor}
                _hover={{ color: hoverColor }}
              >
                الصفحة الرئيسية
              </Link>
              <Link
                href="/join"
                onClick={onClose}
                fontSize="lg"
                color={textColor}
                _hover={{ color: hoverColor }}
              >
                انضم الينا
              </Link>
              <Link
                href="/contact"
                onClick={onClose}
                fontSize="lg"
                color={textColor}
                _hover={{ color: hoverColor }}
              >
                تواصل معنا
              </Link>
              <Link
                href="/images"
                onClick={onClose}
                fontSize="lg"
                color={textColor}
                _hover={{ color: hoverColor }}
              >
                صور الكتاب{" "}
              </Link>
              <IconButton
                aria-label="Toggle color mode"
                icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
                onClick={toggleColorMode}
                size="md"
                isRound={true}
              />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;
