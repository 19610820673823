import React from "react";
import {
  Box,
  Link,
  Text,
  Icon,
  useColorModeValue,
  Code,
} from "@chakra-ui/react";
import { FaSquareXTwitter } from "react-icons/fa6";
import packageInfo from "../../package.json";

const Footer = () => {
  const textColor = useColorModeValue("black", "white");

  return (
    <Box as="footer" w="100%" p={4} color="white" textAlign="center">
      <Text color={textColor} fontSize="sm" mt="3">
        © جميع الحقوق محفوظة للجنة الكتاب السنوي 2024
      </Text>
      <Text color={textColor} fontSize="sm" mt="1">
        الإصدار: <Code>{packageInfo.version}</Code>
      </Text>
      <Text dir="ltr">
        <Link
          href="https://twitter.com/KFUPMYearbook"
          isExternal
          color={textColor}
        >
          <Icon as={FaSquareXTwitter} boxSize="4" mb={-1} mr={1} />
          @KFUPMYearbook
        </Link>
      </Text>
    </Box>
  );
};

export default Footer;
