import React from "react";
import {
  Box,
  Image,
  Text,
  Button,
  Grid,
  GridItem,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const Header = () => {
  return (
    <Box py={10}>
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        alignItems="center"
      >
        <GridItem textAlign="center" mb={{ base: 8, md: 0 }}>
          <Image src="/books.png" alt="Books Image" maxW="300px" mx="auto" />
        </GridItem>

        <GridItem textAlign={{ base: "center", md: "right" }} pr={{ md: 10 }}>
          <Heading as="h1" mb={4} color="green.600">
            لجنة الكتاب السنوي
          </Heading>
          <Text as="h2" fontSize="lg" mb={6}>
            هنا حيث يكتب التاريخ ليصبح ذكرى جميلة
          </Text>
          <HStack
            spacing={4}
            justifyContent={{ base: "center", md: "flex-start" }}
          >
            <Button
              as={RouterLink}
              to="/images"
              colorScheme="green"
              size="md"
              px={6}
              boxShadow="md"
              _hover={{ boxShadow: "lg" }}
            >
              صور الكتاب
            </Button>
            <Button
              as={RouterLink}
              to="/join"
              colorScheme="teal"
              size="md"
              px={6}
              boxShadow="md"
              _hover={{ boxShadow: "lg" }}
            >
              كن جزءاً من الفريق
            </Button>
          </HStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Header;
